import {useEffect,useState} from 'react';
import {Cards, Card} from 'react-cool-cards';
import { AiOutlineReload } from 'react-icons/ai';
import { TailSpin	 } from  'react-loader-spinner'


var geolocation = require('geolocation');




var latitude, longitude;
function App() {
 useEffect(()=>{
  getData();
  },[]);


  var getData = ()=>{
    geolocation.getCurrentPosition(function (err, position) {
      if (err){
        alert("please turn on location on the browser");
      }else{
         latitude = position.coords.latitude;
         longitude = position.coords.longitude;
         
      fetch('https://api.weatherapi.com/v1/current.json?key=c3b5fd94567444e790801805222705&q='+latitude+','+longitude+'&aqi=no')
      .then(response => response.json())
      .then((data)=>{
        
        setTitleStatus("your weather status in " + data.location.name);
        setBodyStatus("the weather is "+data.current.condition.text);
        setIcon(data.current.condition.icon);
        setTemp(data.current.temp_c+" C");
      });
      }
      
    });

    
  }
  const settings = {
    maxWidth: '800px',
    maxHeight: '400px',
    margin: '50px',
    
    shadow: true,
    theme : {
      color: 'rgba(1,1,1,0.3)',
      type: 'rounded',
      fill: 'gradient',
      animation:'rotate'
    }
  }


  const CardsInMain= ()=>{
    return (
      <Cards common={settings} >
      <Card>
        <div style={{width: '100%', height: '100%',display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection: 'column',textAlign:'center',color:`white`,fontFamily:"TimesNewRoman",fontSize:"20px", fontWeight:"600"}}>
       <h1>{titleStatus}</h1>
        <p>{bodyStatus}</p>
        <img src={icon} alt="icon" style={{width:"100px"}}></img>
        <p>{temp}</p>
        <AiOutlineReload onClick={getData} style={{cursor:"pointer",fontSize:"30px",color:"white"}}/>
        
        </div>
      </Card>
      </Cards>
    )
  }

  const [titleStatus,setTitleStatus] = useState("Loading....");
  const [bodyStatus,setBodyStatus] = useState("Loading....");
  const [temp,setTemp] = useState("Loading....");
  const [icon,setIcon] = useState("Loading....");
  
  if (bodyStatus==="Loading....") {
    
    return(
      <div style={{height:"100vh",width:'100vw',backgroundRepeat:"no-repeat",backgroundSize:"100% 100%",backgroundImage:'url("https://wallpaperaccess.com/full/1540001.jpg")' ,display:'flex',flexDirection:"column",alignItems: 'center',justifyContent: 'center'}}>  
      <TailSpin	
    height="75"
    width="75"
    color='white'
    ariaLabel='loading'
   />
    <p style={{color:'white',fontSize:'30px'}}>loading . . .</p>
      </div>
    )
  }else{
    return(
      <div style={{height:"100vh",width:'100vw',backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundImage:'url("https://wallpaperaccess.com/full/1540001.jpg")' ,display:'flex',flexDirection:"column",alignItems: 'center',justifyContent: 'center',color:"white"}}>  
      <CardsInMain />
      <p>Made by Sarband using react</p>
      </div>
    )
  }


}

export default App;
